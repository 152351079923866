import React from 'react';
import Head from 'next/head';

function HeadInject({ icon, title, description, keyword }) {
  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keyword} />

      <link rel='icon' href={icon} />
    </Head>
  );
}

export default HeadInject;
