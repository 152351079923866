const colors = {
    green: "#016853",
    orange: "#fd6c10",
    yellow: "#fec627",
    pink: "#f095cd",
    black: "#000000",
    white: "#ffffff",
    gray: "#3B4B64",
    // gray2: "#9DA5B1",
    red: "#c13d3d",
  
    // ckl
    blue1: "#102D4E",
    blue2: "#214F6B",
    blue3: "#49A0E5",
    blue4: "#55BBE3",
  
    gray1: "#BDBDBD",
    gray2: "#E8E8E8",
  
    background: "#F0F6F8",
    backgroundTitle: "#DAE9EC",
  
    borderTable: "#979797",
  };
  
  export default colors;
  