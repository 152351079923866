/* eslint-disable */
/* eslint-disable no-prototype-builtins */
/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Image from 'next/future/image';
import ScrollNav from 'components/ScrollNav';

function Hero({ language, dataHeader = {}, breadcrump }) {
  const router = useRouter();

  // karena email (as contact-us) di dalem free consultan
  const idFreeConsultan = 20;

  // ambil url terakhir
  const pathname = router.asPath.split('/').pop();

  // breadcrump yang nampil (parent ke 3)
  const [breadcumbEn, setBreadcumbEn] = useState('');
  const [breadcumbId, setBreadcumbId] = useState('');

  // url untuk validasi (parent ke 3)
  const [breadcumbEnUrl, setBreadcumbEnUrl] = useState('');
  const [breadcumbIdUrl, setBreadcumbIdUrl] = useState('');

  // breadcrump parent ke 2
  const [breadcumbParentEn, setBreadcumbParentEn] = useState('');
  const [breadcumbParentId, setBreadcumbParentId] = useState('');

  useEffect(() => {
    // keterangan
    // parent 1 = home

    // ini buat ambil parent ke 2 dan 3
    breadcrump?.HeaderMenus?.forEach((item) => {
      if (item?.hasOwnProperty('child') && item?.id !== idFreeConsultan) {
        item?.child?.forEach((secondItem) => {
          // validate if parent 2 have url (page)
          if (
            (secondItem?.hasOwnProperty('id_url') ||
              secondItem?.hasOwnProperty('en_url')) &&
            (pathname === secondItem?.id_url || pathname === secondItem?.en_url)
          ) {
            setBreadcumbParentEn(item.en_menu_name);
            setBreadcumbParentId(item.id_menu_name);

            // if have parent 3
          } else {
            secondItem?.child?.forEach((thirdItem) => {
              if (thirdItem.en_url === pathname && language === 'EN') {
                setBreadcumbEnUrl(secondItem.en_url);
                setBreadcumbIdUrl(secondItem.id_url);
                setBreadcumbEn(secondItem.en_menu_name);
                setBreadcumbId(secondItem.id_menu_name);
                setBreadcumbParentEn(item.en_menu_name);
                setBreadcumbParentId(item.id_menu_name);
              }
              if (thirdItem.id_url === pathname && language === 'ID') {
                setBreadcumbEnUrl(secondItem.en_url);
                setBreadcumbIdUrl(secondItem.id_url);
                setBreadcumbEn(secondItem.en_menu_name);
                setBreadcumbId(secondItem.id_menu_name);
                setBreadcumbParentEn(item.en_menu_name);
                setBreadcumbParentId(item.id_menu_name);
              }
            });
          }
        });
      }
    });
  }, [dataHeader]);

  const handleClick = (url) => {
    if (url !== '#')
      window.open(
        url,
        // "https://wa.me/0831?text=oke",
        '_blank',
        'noopener,noreferrer'
      );
  };

  return (
    <div
      className="top-page-courier-service top-page-cs-air-freight"
      style={{ backgroundImage: 'url(' + dataHeader?.banner_img + ')' }}
    >
      <div className="page-bg-overlay"></div>
      <div className="col-in-area">
        <div></div>
        <div className="container">
          <ol className="breadcrumb">
            {/* 1 */}
            <li>
              <Link href="/" locale={language === 'ID' ? 'id' : 'en'}>
                <a>Home</a>
              </Link>
            </li>
            {/* 1 */}

            {/* 2 */}
            {language === 'EN' && breadcumbParentEn ? (
              <li>{breadcumbParentEn}</li>
            ) : (
              <></>
            )}
            {language === 'ID' && breadcumbParentId ? (
              <li>{breadcumbParentId}</li>
            ) : (
              <></>
            )}
            {/* 2 */}

            {/* 3 */}
            {language === 'EN' && breadcumbEn ? (
              <li>
                {breadcumbEnUrl ? (
                  <Link
                    href={'/' + breadcumbEnUrl}
                    locale={language === 'ID' ? 'id' : 'en'}
                  >
                    <a>{breadcumbEn}</a>
                  </Link>
                ) : (
                  <>{breadcumbEn}</>
                )}
              </li>
            ) : (
              <></>
            )}
            {language === 'ID' && breadcumbId ? (
              <li>
                {breadcumbIdUrl ? (
                  <Link
                    href={'/' + breadcumbIdUrl}
                    locale={language === 'ID' ? 'id' : 'en'}
                  >
                    <a>{breadcumbId}</a>
                  </Link>
                ) : (
                  <>{breadcumbId}</>
                )}
              </li>
            ) : (
              <></>
            )}
            {/* 3 */}

            {/* 4 */}
            <li className="active">
              {language === 'EN' ? dataHeader.en_title : dataHeader.id_title}
            </li>
            {/* 4 */}
          </ol>
          <h1
            className="title"
            dangerouslySetInnerHTML={{
              __html:
                language === 'EN' ? dataHeader.en_title : dataHeader.id_title
            }}
          ></h1>
          <p
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html:
                language === 'EN' ? dataHeader.en_desc : dataHeader.id_desc
            }}
          ></p>

          <div className="d-flex gap-3">
            {dataHeader?.headerCTA && (
              <div className="col-btn-talk">
                <button
                  className="btn-talk"
                  style={{ backgroundColor: dataHeader?.headerCTA?.bg_color }}
                  onClick={() => handleClick(dataHeader?.headerCTA?.url)}
                  type="button"
                >
                  {/* <img
                src={dataHeader?.headerCTA?.icon}
                alt={dataHeader?.headerCTA?.alt_icon}
              /> */}
                  <Image
                    src={dataHeader?.headerCTA?.icon}
                    alt={dataHeader?.headerCTA?.alt_icon}
                    width={20}
                    height={20}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        language === 'EN'
                          ? dataHeader?.headerCTA?.en_name
                          : dataHeader?.headerCTA?.id_name
                    }}
                  ></span>
                </button>
              </div>
            )}

            {/* DOWNLOAD */}
            {dataHeader?.additional_cta_btn_id ? (
              <div className="col-btn-talk">
                <button
                  className="btn-talk"
                  style={{
                    backgroundColor: dataHeader?.additionalCTA?.bg_color
                  }}
                  onClick={() => handleClick(dataHeader?.additionalCTA?.url)}
                  type="button"
                >
                  <Image
                    src={dataHeader?.additionalCTA?.icon}
                    alt={dataHeader?.additionalCTA?.alt_icon}
                    width={20}
                    height={20}
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        language === 'EN'
                          ? dataHeader?.additionalCTA?.en_name
                          : dataHeader?.additionalCTA?.id_name
                    }}
                  ></span>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <ScrollNav />
      </div>
    </div>
  );
}

export default Hero;
