/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
// import COM from "../../public/assets/com.jpg";

// import Link from 'next/link';

function BackgroundBannerDefault({ data, language, isLandingPage = false }) {
  useEffect(() => {
    const handleContentLoaded = () => {
      var stage = document.getElementById('stage');
      var fadeComplete = function () {
        stage.appendChild(arr[0]);
      };
      var arr = stage.getElementsByTagName('a');

      for (var i = 0; i < arr.length; i++) {
        arr[i].addEventListener('animationend', fadeComplete, false);
      }
    };
    handleContentLoaded();
  }, [data]);

  return (
    <div id="stage" className="stage">
      {data?.map((item, index) => {
        return item.url ? (
          <Link
            key={index}
            href={item.url}
            locale={language === 'ID' ? 'id' : 'en'}
            passHref={true}
          >
            <a>
              {/* <span key={index}> */}
              {/* <img
              style={{
                filter: "brightness(0.65)",
                // filter: ""
              }}
              alt={language === "EN" ? item?.en_alt_img : item?.id_alt_img}
              src={item[`${language.toLowerCase()}_img`]}
            ></img> */}
              <Image
                style={{
                  filter: 'brightness(0.65)'
                  // filter: ""
                }}
                alt={language === 'EN' ? item?.en_alt_img : item?.id_alt_img}
                src={item[`${language.toLowerCase()}_img`]}
                layout="fill"
                // alt="p"
                // src={COM}
                // loading="eager"
              />
              <div className="col-text">
                {isLandingPage ? (
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: item[`${language.toLowerCase()}_detail`]
                    }}
                  ></h1>
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item[`${language.toLowerCase()}_detail`]
                    }}
                  ></p>
                )}
              </div>
              {/* </span> */}
            </a>
          </Link>
        ) : (
          <span key={index}>
            {/* <img
            style={{
              filter: "brightness(0.65)",
              // filter: ""
            }}
            alt={language === "EN" ? item?.en_alt_img : item?.id_alt_img}
            src={item[`${language.toLowerCase()}_img`]}
          ></img> */}
            <Image
              style={{
                filter: 'brightness(0.65)'
                // filter: ""
              }}
              alt={language === 'EN' ? item?.en_alt_img : item?.id_alt_img}
              src={item[`${language.toLowerCase()}_img`]}
              layout="fill"
              // alt="p"
              // src={COM}
              // loading="eager"
            />
            <div className="col-text">
              {isLandingPage ? (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: item[`${language.toLowerCase()}_detail`]
                  }}
                ></h1>
              ) : (
                <p
                  dangerouslySetInnerHTML={{
                    __html: item[`${language.toLowerCase()}_detail`]
                  }}
                ></p>
              )}
            </div>
          </span>
        );
      })}
    </div>
  );
}

export default BackgroundBannerDefault;
