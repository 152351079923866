/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { NextSeo } from 'next-seo';
import Image from 'next/future/image';

function LoadingScreen() {
  return (
    <>
      <NextSeo title='Loading ...' />

      <div className='d-flex justify-content-center align-items-center vh-100'>
        <div>
          {/* <img
            alt='logo'
            src='/assets/icons/logo_loading.gif'
            height={200}
          /> */}
           <Image
            alt='logo'
            src={`${process.env.URL_FRONTEND}/assets/icons/logo_loading.gif`}
            height={200}
            width={200}
          />
        </div>
      </div>
    </>
  );
}

export default LoadingScreen;
