/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';

function WAFixed({ data }) {
  const handleClick = () => {
    // url;

    if (data?.url !== '#')
      window.open(data?.url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='position-fixed-whatsapp'>
      <button className='btn-talk' onClick={handleClick} type='button'>
        {/* <img src={data?.icon} alt={data?.alt_icon} /> */}
        <Image width={30} height={30} src={data?.icon} alt={data?.alt_icon} />
      </button>
    </div>
  );
}

export default WAFixed;
