/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert";
import { primary } from "utils/styleReactSelect";
import {
  CFormCheck,
  CFormLabel,
} from "@coreui/react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Checkbox = ({
  register,
  label,
  name,
  disabled,
  onChange,

  // validation
  errors,
  isReqMsg,
}) => {

  return (
    <div className="costum-input">
          <CFormCheck
            // className="costum-input-input"
            className="form-input"
            disabled={disabled}
            {...register(name, {
              required: isReqMsg,
            }
            )}
            onChange={onChange}
            type="checkbox"
          />
          &nbsp;
          <CFormLabel>{label}</CFormLabel>
         
      {/* MSG ERRORS */}
      {errors &&
        <p style={{ color: "#e55353", fontSize: "13px", marginTop: "5px" }}>
          {errors[name] && errors[name]?.message}
        </p>
      }
    </div>
  );
};

export default Checkbox;
