/* eslint-disable */
import React from "react";
import Image from "next/future/image";

function CTA({ CTAButton, id_desc, en_desc, language }) {
  const handleClick = (url) => {
    if (url !== "#") window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="content">
      <div className="col-talk">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h4
                dangerouslySetInnerHTML={{
                  __html: language === "EN" ? en_desc : id_desc,
                }}
              ></h4>
            </div>
            <div className="col-md-3">
              <div className="col-btn-talk">
                <button
                  className="btn-talk"
                  style={{ backgroundColor: CTAButton?.bg_color }}
                  onClick={() => handleClick(CTAButton?.url)}
                  type="button"
                >
                  {/* <img src={CTAButton?.icon} alt={CTAButton?.alt_icon} /> */}
                  <Image
                    src={CTAButton?.icon}
                    alt={CTAButton?.alt_icon}
                    width={20}
                    height={20}
                    // layout="fill"
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        language === "EN"
                          ? CTAButton?.en_name
                          : CTAButton?.id_name,
                    }}
                  ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTA;
