import HeadInject from './HeadInject';
import ResponseError from './ResponseError';
import BackgroundBannerDefault from './BackgroundBannerDefault';
import Sidebar from './Sidebar';
import Hero from './Hero';
import WhyChooseUs from './WhyChooseUs';
import FooterFrontend from './FooterFrontend';
import OurServices from './OurServices';
import CTA from './CTA';
import OurPartner from './OurPartner';
import OurClient from './OurClient';
import WAFixed from './WAFixed';
import LoadingScreen from './LoadingScreen';
import CostumInput from './CostumInput';
import Checkbox from './Checkbox';
import InputCV from './InputCV';
import ScrollNav from './ScrollNav';

export {
  HeadInject,
  ResponseError,
  BackgroundBannerDefault,
  Sidebar,
  Hero,
  WhyChooseUs,
  FooterFrontend,
  OurServices,
  CTA,
  OurPartner,
  OurClient,
  WAFixed,
  LoadingScreen,
  CostumInput,
  Checkbox,
  InputCV,
  ScrollNav
};
