/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import GLOBE from '../../public/assets/icons/globe.png';
import HOME from '../../public/assets/icons/home.png';
import MENU_MOBILE from '../../public/assets/icons/btn-mobile-menu.png';
import ICON_SEARCH from '../../public/assets/icons/search-blue.png';

import { useSelector } from 'react-redux';
import Link from 'next/link';
import Image from 'next/future/image';

function Sidebar({
  openNav,
  closeNav,
  topFunction,
  data = {},
  changeLanguage,
  language
}) {
  const [isHovering, setIsHovering] = useState('');

  const idFreeConsultation = 20;
  const idCourier = 2;
  const idInternational = 7;
  const idEmail = 22;

  const handleClickFreeConsul = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleMouseEnter = (id) => {
    setIsHovering(id);
  };

  const handleMouseLeave = () => {
    setIsHovering('');
  };

  return (
    <>
      <span className="right display-mobile">
        <ul className="top-nav-menu">
          <li>
            <span>
              {/* <img src="../../assets/icons/globe.png" alt="img" /> */}

              <Image
                alt="ckl logo"
                // className="image-icon"
                src={`${process.env.URL_FRONTEND}/assets/icons/globe.png`}
                width={30}
                height={30}
              />

              <span className="text-language">{language}</span>
            </span>
            <ul className="sub-nav-content">
              {language === 'ID' && (
                <li
                  onClick={changeLanguage}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <span>EN</span>
                </li>
              )}

              {language === 'EN' && (
                <li
                  onClick={changeLanguage}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <span>ID</span>
                </li>
              )}
            </ul>
          </li>
        </ul>

        <span
          className="home"
          style={{ cursor: 'pointer' }}
          onClick={topFunction}
        >
          {/* <img src="../../assets/icons/home.png" alt="icon" /> */}

          <Image
            alt="home"
            // className="image-icon"
            src={`${process.env.URL_FRONTEND}/assets/icons/home.png`}
            width={30}
            height={30}
          />
        </span>

        <span onClick={openNav} className="btn-nav-mobile">
          {/* <img src="../../assets/icons/btn-mobile-menu.png" alt="icon" /> */}

          <Image alt="icon" src={MENU_MOBILE} width={30} height={30} />
        </span>
      </span>

      <div className="clr"></div>

      <div id="mySidenav" className="sidenav">
        <div className="col-top-nav-push">
          <Link href="/" locale={language === 'ID' ? 'id' : 'en'}>
            <span>
              {/* <img src={data?.sidebar_logo} alt={data?.alt_sidebar_logo} /> */}

              <Image
                src={data?.sidebar_logo}
                alt={data?.alt_sidebar_logo}
                // layout="fill"
                width={125}
                height={62}
                // loading="eager"
              />
            </span>
          </Link>
          <span className="closebtn" onClick={closeNav}>
            &times;
          </span>
        </div>

        <div className="col-bottom-nav-push">
          <ul className="nav-menu-push">
            {data.HeaderMenus !== undefined &&
              data?.HeaderMenus.map(
                (item, index) =>
                  item.id === idFreeConsultation && (
                    <li key={index}>
                      <h3>
                        <span className="link-sub-nav">
                          {language === 'EN'
                            ? item.en_menu_name
                            : item.id_menu_name}
                        </span>
                      </h3>
                      <ul className="sub-nav-content">
                        {item.child.map((child, childIndex) => (
                          <>
                            <li key={childIndex}>
                              {child?.id === idEmail ? (
                                <Link
                                  href={
                                    language === 'ID'
                                      ? '/' + child?.id_url
                                      : '/' + child?.en_url
                                  }
                                  locale={language === 'ID' ? 'id' : 'en'}
                                >
                                  <a
                                    style={{
                                      color:
                                        isHovering === child.id ? '#02bee8' : ''
                                      // cursor: 'pointer',
                                    }}
                                    onMouseEnter={() =>
                                      child.hasOwnProperty('id_url') ||
                                      child.hasOwnProperty('en_url')
                                        ? handleMouseEnter(child.id)
                                        : null
                                    }
                                    onMouseLeave={() =>
                                      child.hasOwnProperty('id_url') ||
                                      child.hasOwnProperty('en_url')
                                        ? handleMouseLeave(child.id)
                                        : null
                                    }
                                  >
                                    {language === 'ID'
                                      ? child.id_menu_name
                                      : child.en_menu_name}
                                  </a>
                                </Link>
                              ) : (
                                <span
                                  className="link-sub-nav"
                                  style={{
                                    // cursor: 'pointer',
                                    color:
                                      isHovering === child.id ? '#02bee8' : ''
                                  }}
                                  onClick={() =>
                                    handleClickFreeConsul(
                                      language === 'EN'
                                        ? child.en_url
                                        : child.id_url
                                    )
                                  }
                                  onMouseEnter={() =>
                                    handleMouseEnter(child.id)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(child.id)
                                  }
                                >
                                  {language === 'ID'
                                    ? child.id_menu_name
                                    : child.en_menu_name}
                                </span>
                              )}
                            </li>
                          </>
                        ))}
                      </ul>
                    </li>
                  )
              )}
          </ul>
          <form>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search & Find"
                aria-label="Search"
                aria-describedby="search-1"
              />
              <span className="input-group-text" id="search-1">
                {/* <img src="../../assets/icons/search-blue.png" alt="icon" /> */}

                <Image
                  src={ICON_SEARCH}
                  alt="search icon"
                  className="image-icon"
                  // layout="fill"
                  // loading="eager"
                  width={30}
                  height={30}
                />
              </span>
            </div>
          </form>

          <ul className="top-nav-menu">
            {data.HeaderMenus !== undefined &&
              data?.HeaderMenus.map(
                (item, index) =>
                  item.id === idFreeConsultation && (
                    <li key={index}>
                      <h3>
                        <span className="link-sub-nav">
                          {language === 'EN'
                            ? item.en_menu_name
                            : item.id_menu_name}
                        </span>
                      </h3>
                      <ul className="sub-nav-content">
                        {item.child.map((child, childIndex) => (
                          <>
                            {child?.id === idEmail ? (
                              <li
                                key={childIndex}
                                style={{ cursor: 'pointer' }}
                              >
                                <Link
                                  href={
                                    language === 'ID'
                                      ? '/' + child?.id_url
                                      : '/' + child?.en_url
                                  }
                                  locale={language === 'ID' ? 'id' : 'en'}
                                >
                                  <a
                                    style={{
                                      color:
                                        isHovering === child.id ? '#02bee8' : ''
                                      // cursor: 'pointer',
                                    }}
                                    onMouseEnter={() =>
                                      child.hasOwnProperty('id_url') ||
                                      child.hasOwnProperty('en_url')
                                        ? handleMouseEnter(child.id)
                                        : null
                                    }
                                    onMouseLeave={() =>
                                      child.hasOwnProperty('id_url') ||
                                      child.hasOwnProperty('en_url')
                                        ? handleMouseLeave(child.id)
                                        : null
                                    }
                                  >
                                    {language === 'ID'
                                      ? child.id_menu_name
                                      : child.en_menu_name}
                                  </a>
                                </Link>
                              </li>
                            ) : (
                              <li
                                key={childIndex}
                                style={{ cursor: 'pointer' }}
                              >
                                <span
                                  className="link-sub-nav"
                                  style={{
                                    // cursor: 'pointer',
                                    color:
                                      isHovering === child.id ? '#02bee8' : ''
                                  }}
                                  onClick={() =>
                                    handleClickFreeConsul(
                                      language === 'EN'
                                        ? child.en_url
                                        : child.id_url
                                    )
                                  }
                                  onMouseEnter={() =>
                                    handleMouseEnter(child.id)
                                  }
                                  onMouseLeave={() =>
                                    handleMouseLeave(child.id)
                                  }
                                >
                                  {language === 'ID'
                                    ? child.id_menu_name
                                    : child.en_menu_name}
                                </span>
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </li>
                  )
              )}
          </ul>

          {/*  */}
          <form>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search & Find"
                aria-label="Search"
                aria-describedby="search-1"
              />
              <span className="input-group-text" id="search-1">
                {/* <img src="../../assets/icons/search-blue.png" alt="icon" /> */}

                <Image
                  src={ICON_SEARCH}
                  alt="search icon"
                  className="image-icon"
                  // layout="fill"
                  // loading="eager"
                  width={30}
                  height={30}
                />
              </span>
            </div>
          </form>
          {/*  */}

          <ul className="nav-menu-push">
            {data?.HeaderMenus !== undefined &&
              data?.HeaderMenus.map(
                (item, index) =>
                  item.id !== idFreeConsultation && (
                    <>
                      {item.hasOwnProperty('child') ? (
                        <>
                          <li key={index}>
                            <span className="link-sub-nav">
                              {language === 'ID'
                                ? item.id_menu_name
                                : item.en_menu_name}
                            </span>
                            <ul className="sub-nav-content">
                              {item.child.map((child, childIndex) => (
                                <li key={childIndex}>
                                  {child.hasOwnProperty('id_url') ||
                                  child.hasOwnProperty('en_url') ? (
                                    <Link
                                      href={
                                        language === 'ID'
                                          ? '/' + child?.id_url
                                          : '/' + child?.en_url
                                      }
                                      locale={language === 'ID' ? 'id' : 'en'}
                                    >
                                      <a
                                        style={{
                                          color:
                                            isHovering === child.id
                                              ? '#02bee8'
                                              : ''
                                          // cursor: 'pointer',
                                        }}
                                        onMouseEnter={() =>
                                          child.hasOwnProperty('id_url') ||
                                          child.hasOwnProperty('en_url')
                                            ? handleMouseEnter(child.id)
                                            : null
                                        }
                                        onMouseLeave={() =>
                                          child.hasOwnProperty('id_url') ||
                                          child.hasOwnProperty('en_url')
                                            ? handleMouseLeave(child.id)
                                            : null
                                        }
                                      >
                                        {language === 'ID'
                                          ? child.id_menu_name
                                          : child.en_menu_name}
                                      </a>
                                    </Link>
                                  ) : (
                                    <span>
                                      {language === 'ID'
                                        ? child.id_menu_name
                                        : child.en_menu_name}
                                    </span>
                                  )}
                                  {child.hasOwnProperty('child') ? (
                                    <>
                                      <ul className="subs-nav">
                                        {child.child.map((childofchild) => (
                                          <>
                                            <li>
                                              <Link
                                                href={
                                                  child?.id === idCourier
                                                    ? `/courier-services/${
                                                        language === 'EN'
                                                          ? childofchild?.en_url
                                                          : childofchild?.id_url
                                                      }`
                                                    : child.id ===
                                                      idInternational
                                                    ? `/international-delivery/${
                                                        language === 'EN'
                                                          ? childofchild?.en_url
                                                          : childofchild?.id_url
                                                      }`
                                                    : `/supply-chain/${
                                                        language === 'EN'
                                                          ? childofchild?.en_url
                                                          : childofchild?.id_url
                                                      }`
                                                }
                                                locale={
                                                  language === 'ID'
                                                    ? 'id'
                                                    : 'en'
                                                }
                                              >
                                                <a>
                                                  {language === 'ID'
                                                    ? childofchild.id_menu_name
                                                    : childofchild.en_menu_name}
                                                </a>
                                              </Link>
                                            </li>
                                          </>
                                        ))}
                                      </ul>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            <Link
                              href={`/${
                                language === 'ID' ? item.id_url : item.en_url
                              }`}
                              locale={language === 'ID' ? 'id' : 'en'}
                            >
                              <a>
                                {language === 'ID'
                                  ? item.id_menu_name
                                  : item.en_menu_name}
                              </a>
                            </Link>
                          </li>
                        </>
                      )}
                    </>
                  )
              )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
