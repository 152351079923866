/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { CFormInput, CFormLabel, CButton } from '@coreui/react';
import Swal from 'sweetalert';
import colors from 'utils/colors';
import { MdUpload } from 'react-icons/md';

function InputCV({
  label = 'Background Image',
  CV,
  setCV,
  nameFile,
  setNameFile,
  inputRef,

  // validation
  register,
  name,
  errors,
  setValue
}) {
  const handleFileBG = (e) => {
    const file = e.target.files[0];

    // 5 MB
    if (file.size > 5242880) {
      Swal('Ukuran file terlalu besar, maksimal 5MB', '', 'error');
    } else if (file.type !== 'application/pdf') {
      Swal(
        'File format tidak sesuai, format yang diterima harus pdf',
        '',
        'error'
      );
    } else if (file) {
      setNameFile(file?.name);
      setCV(file);

      // validate
      setValue(name, file);
    }
  };

  return (
    <div>
      <CFormLabel>{label}</CFormLabel>
      <CFormLabel style={{ color: 'red', marginLeft: '5px' }}>*</CFormLabel>
      <>
        <div className="d-flex justify-content-between">
          <div
            style={{
              background: 'rgb(2, 46, 80)',
              fontWeight: '600',
              borderRadius: '5px',
              color: colors.white,
              padding: '8px 22px',
              textAlign: 'center',
              border: `1px solid rgb(2, 46, 80)`,
              marginBottom: '10px',
              width: '45%',
              cursor: 'pointer'
            }}
            onClick={() => inputRef.current.click()}
          >
            Upload Resume / CV <MdUpload />
          </div>
          <div
            style={{
              background: colors.gray1,
              fontWeight: '600',
              borderRadius: '5px',
              padding: '8px 22px',
              textAlign: 'left',
              border: `1px solid ${colors.gray1}`,
              marginBottom: '10px',
              width: '45%'
            }}
            align="right"
          >
            {nameFile ? nameFile : 'Tidak Ada File'}
          </div>
        </div>
        <div>
          <b>*Format File PDF</b>
          <br />
          <b>*Max File 5 MB</b>
        </div>
        <CFormInput
          {...register(name, { required: 'Required' })}
          ref={inputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileBG}
        />

        <p style={{ color: '#e55353', fontSize: '13px', marginTop: '5px' }}>
          {errors[name] && errors[name]?.message}
        </p>
      </>
    </div>
  );
}

export default InputCV;
