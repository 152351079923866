/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/future/image';

function OurServices({
  en_service_text,
  id_service_text,
  dataOurServices,
  language,
}) {
  return (
    <div className='content'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <h3
              className='title'
              dangerouslySetInnerHTML={{
                __html: language === 'EN' ? en_service_text : id_service_text,
              }}
            ></h3>
          </div>
        </div>
        <div className='col-bottom-nav-tabs'>
          <div className='row'>
            {dataOurServices?.map((item, index) => (
              <div className='col-sm-6 col-md-6' key={index}>
                <div className='col-thumbnail'>
                  <div className='box-img'>
                    {/* <img
                      src={language === 'EN' ? item.en_img : item.id_img}
                      alt={
                        language === 'EN' ? item.en_alt_img : item.id_alt_img
                      }
                    /> */}
                    <Image 
                      width={120}
                      height={50}
                      src={language === 'EN' ? item.en_img : item.id_img}
                      alt={
                        language === 'EN' ? item.en_alt_img : item.id_alt_img
                      }
                    />
                  </div>
                  <div className='caption'>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: language === 'EN' ? item.en_name : item.id_name,
                      }}
                    ></h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: language === 'EN' ? item.en_desc : item.id_desc,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
