/* eslint-disable */
import React from 'react';
import Image from 'next/future/image';

function OurClient({ client, id_title, en_title, language }) {
  return (
    <div className='content'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <center>
              <h3
                className='title'
                dangerouslySetInnerHTML={{
                  __html: language === 'EN' ? en_title : id_title,
                }}
              ></h3>
            </center>
          </div>
        </div>
        <div className='col-our-client'>
          <div className='row justify-content-md-center'>
            {client?.map((item, index) => (
              <div className='col-sm-6 col-md-3 mb-5' key={index}>
                {/* <img src={item.icon} alt={item?.alt_icon} /> */}
                <Image width={120} height={70} src={item.icon} alt={item?.alt_icon} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurClient;
