/* eslint-disable @next/next/no-img-element */
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";
import Image from "next/image";
import React, { useEffect, useState } from "react";

function WhyChooseUs({
  en_name,
  id_name,
  dataBtn = [],
  dataContent = [],
  selected,
  setSelected,
  language,
}) {
  const styled = {
    "--cui-accordion-active-bg": "#dcf2ff",
    "--cui-accordion-active-color": "1px solid",
  };

  const [width, setWidth] = useState(undefined);

  function handleResize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="content">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h3
              className="title"
              dangerouslySetInnerHTML={{
                __html: language === "EN" ? en_name : id_name,
              }}
            ></h3>
          </div>
        </div>

        {/* if min width 360px */}
        {width > 1200 && (
          <div className="col-tabs-why-choose-us">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex align-items-start">
                  <div
                    className="nav flex-column nav-pills me-3"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    {dataBtn?.map((item, index) => (
                      <button
                        key={index}
                        className={`nav-link ${
                          selected === item.id ? "active" : ""
                        }`}
                        onClick={() => setSelected(item.id)}
                        dangerouslySetInnerHTML={{
                          __html:
                            language === "EN" ? item.en_title : item.id_title,
                        }}
                      ></button>
                    ))}
                  </div>
                  <div className="tab-content" id="v-pills-tabContent">
                    {dataContent?.map((item, index) => (
                      <div
                        className={`tab-pane fade ${
                          selected === item.id ? "show active" : ""
                        }`}
                        key={index}
                      >
                        {/* <img
                          src={language === "EN" ? item.en_img : item.id_img}
                          alt={
                            language === "EN"
                              ? item.alt_en_img
                              : item.alt_id_img
                          }
                        /> */}

                        <Image
                          src={language === "EN" ? item.en_img : item.id_img}
                          alt={
                            language === "EN"
                              ? item.alt_en_img
                              : item.alt_id_img
                          }
                          width={918}
                          height={516}
                        />
                        <h3
                          className="title-content-tabs"
                          dangerouslySetInnerHTML={{
                            __html:
                              language === "EN" ? item.en_title : item.id_title,
                          }}
                        ></h3>
                        <p
                          className="text-content-tabs"
                          dangerouslySetInnerHTML={{
                            __html:
                              language === "EN" ? item.en_desc : item.id_desc,
                          }}
                        ></p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* mobiile */}
        {width < 1200 && (
          <CAccordion flush style={styled}>
            {dataContent.map((item, index) => (
              <CAccordionItem key={index}>
                <CAccordionHeader>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: language === "EN" ? item.en_title : item.id_title,
                    }}
                  ></p>
                </CAccordionHeader>
                <CAccordionBody>
                  <div
                    className={`${selected === item.id ? "show active" : ""}`}
                  >
                    {/* <img
                      src={language === "EN" ? item.en_img : item.id_img}
                      alt={
                        language === "EN" ? item.alt_en_img : item.alt_id_img
                      }
                      
                    /> */}

                    <Image
                      src={language === "EN" ? item.en_img : item.id_img}
                      alt={
                        language === "EN" ? item.alt_en_img : item.alt_id_img
                      }
                      width={896}
                      height={504}
                    />

                    <h3
                      style={{ marginTop: "30px" }}
                      className="title-content-tabs"
                      dangerouslySetInnerHTML={{
                        __html:
                          language === "EN" ? item.en_title : item.id_title,
                      }}
                    ></h3>
                    <p
                      className="text-content-tabs"
                      dangerouslySetInnerHTML={{
                        __html: language === "EN" ? item.en_desc : item.id_desc,
                      }}
                    ></p>
                  </div>
                </CAccordionBody>
              </CAccordionItem>
            ))}
          </CAccordion>
        )}
      </div>
    </div>
  );
}

export default WhyChooseUs;
