/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';
import Image from 'next/future/image';

function FooterFrontend({ footer, language }) {
  return (
    <footer>
      <div className="container">
        <div className="top-footer">
          <div className="row">
            <div className="col-md-4">
              <Link href="/" locale={language === 'ID' ? 'id' : 'en'}>
                <a>
                  {/* <img
                    src={footer?.logo}
                    className='logo-ckl'
                    alt={footer?.alt_logo}
                  /> */}
                  <Image
                    width={130}
                    height={70}
                    src={footer?.logo}
                    className="logo-ckl"
                    alt={footer?.alt_logo}
                    // layout="fill"
                  />
                </a>
              </Link>
            </div>
            <div className="col-md-8">
              <div className="row">
                {footer?.FooterMenus?.map((list, index) => (
                  <div
                    key={index}
                    className={`col-md-${
                      index === 0 ? '2' : index === 3 ? '4' : '3'
                    }`}
                  >
                    <h4
                      dangerouslySetInnerHTML={{
                        __html:
                          language === 'EN'
                            ? list.en_menu_name
                            : list.id_menu_name
                      }}
                    ></h4>
                    {list?.child.map((item, itemIndex) => (
                      <p key={itemIndex}>
                        <Link
                          href={
                            language === 'EN'
                              ? item.en_url
                                ? '/' + item.en_url
                                : '#'
                              : item.id_url
                              ? '/' + item.id_url
                              : '#'
                          }
                          locale={language === 'ID' ? 'id' : 'en'}
                        >
                          <a
                            dangerouslySetInnerHTML={{
                              __html:
                                language === 'EN'
                                  ? item.en_menu_name
                                  : item.id_menu_name
                            }}
                          ></a>
                        </Link>
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <div className="row">
            <div className="col-md-6">
              <p
                dangerouslySetInnerHTML={{
                  __html: footer?.copyright
                }}
              ></p>
            </div>
            <div className="col-md-6">
              <div className="col-medsos">
                <p className="d-flex justify-content-md-end align-items-center">
                  Follow Us :
                  {footer?.FooterSocMeds?.map((list, index) => (
                    <Link
                      href={list.url ? list.url : '#'}
                      locale={language === 'ID' ? 'id' : 'en'}
                      key={index}
                    >
                      <a>
                        {/* <img
                          src={list?.icon}
                          className='icon-footer'
                          alt={list?.name}
                        /> */}
                        <div className="m-1">
                          <Image
                            width={20}
                            height={20}
                            src={list?.icon}
                            alt={list?.name}
                            // layout="fill"
                          />
                        </div>
                      </a>
                    </Link>
                  ))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterFrontend;
