import React from 'react';
import Image from 'next/image';
import ChevronDown from 'public/assets/icons/chevrons-down.svg';

function ScrollNav() {
  return (
    <div>
      <a href="#scroll" style={{ cursor: 'pointer' }}>
        <Image
          width={100}
          height={80}
          src={ChevronDown}
          alt="arrow-navigation"
          className="arrow-navigation"
        />
      </a>

      <div id="scroll"></div>
    </div>
  );
}

export default ScrollNav;
